import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetSingleReservationQuery } from 'src/redux/rtkQuery/apiSlice';
import { buildRegistrationWizardSteps } from 'src/sections/registration/types';
import { useReservationInfo } from '../ReservationInfoContext';
import { ReservationGeneral } from '../models/ReservationGeneral';
import { RegistrationWizard } from '../sections/registration/RegistrationWizard';

// ----------------------------------------------------------------------

type RegistrationGuardProps = {
  children: React.ReactNode;
};

//NOTE: Wherever we have the registration guard, we need a SignatureProvider, _under the current design_.
//		This is due to the rental agreement step.

export default function RegistrationGuard({ children }: RegistrationGuardProps) {
  const [workingReservationName, setWorkingReservationName] = useState<string>('');
  const [reservation, setReservation] = useState<ReservationGeneral | null>(null);
  const { reservationsByName } = useReservationInfo();
  const { reservationName: pathReservationName } = useParams<{ reservationName: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const {
    isFetching: reservationFetching,
    isSuccess: reservationSuccess,
    data: reservationData,
    refetch: refetchReservation,
  } = useGetSingleReservationQuery(
    { reservationName: pathReservationName },
    {
      skip: !pathReservationName,
    }
  );

  const refreshReservation = useCallback(
    (overrideBusy: boolean = false) => {
      if (reservationFetching && !overrideBusy) {
        // already refetching
        return;
      }
      refetchReservation();
    },
    [refetchReservation, reservationFetching]
  );

  useEffect(() => {
    if (!reservationData) {
      return;
    }
    setReservation(reservationData);
  }, [reservationData, reservationSuccess]);

  // find and set the reservation from the url, when applicable
  let updateReservation = false;
  if (!reservation) {
    // first-time check needed
    updateReservation = true;
  } else if (reservation.name !== pathReservationName) {
    // the selected reservation has changed
    updateReservation = true;
  }

  if (updateReservation && pathReservationName) {
    const contextReservation = reservationsByName.get(pathReservationName);
    if (contextReservation) {
      setReservation(contextReservation);
    }
  }

  if (reservation && reservation.require_guest_registration) {
    if (reservation.guest_registration_complete) {
      if (workingReservationName !== '') {
        setWorkingReservationName('');

        // mark these off in a global
        //TODO: We could build a full context for this, but they're extremely rare,
        //		and are not needed elsewhere. We don't have the time. If anything,
        //		fall back to session storage.
        if (!(window as any).drr_completedRegistrations) {
          // lazy create
          (window as any).drr_completedRegistrations = new Set<string>();
        }

        if (!(window as any).drr_completedRegistrations.has(reservation.name)) {
          (window as any).drr_completedRegistrations.add(reservation.name);

          enqueueSnackbar('Thank you for completing guest registration!', {
            variant: 'success',
            autoHideDuration: 10 * 1000,
            onClick: (x: any) => {},
          });
        }
      }
      // else already shown
    } else if (reservation.guest_registration_requirements.length > 0) {
      if (workingReservationName !== reservation.name) {
        setWorkingReservationName(reservation.name);
      }
      const hideResortDisclosures = Object.keys(reservation.resort_disclosures).length === 0;
      const registrationSteps = buildRegistrationWizardSteps(reservation, hideResortDisclosures);
      return (
        <>
          {reservation && (
            <RegistrationWizard
              reservation={reservation}
              registrationSteps={registrationSteps}
              callbackForceGuardRefetch={(overrideBusy: boolean) => {
                refreshReservation(overrideBusy);
              }}
            />
          )}
          {children}
        </>
      );
    }
  }

  return <> {children} </>;
}
