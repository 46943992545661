import { FirebaseError, initializeApp } from 'firebase/app';
import {
  ActionCodeSettings,
  AuthErrorCodes,
  User,
  connectAuthEmulator,
  getAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { connectFirestoreEmulator, doc, getDoc, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { createContext, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { checkClaimsForHost } from 'src/config/brandUtils';
import { CheckLocalDev, FIREBASE_API } from '../config-global';
import { InstantiateUserFromJSON, UserAccountGeneral } from '../models/UserAccountGeneral';
import { cloud_createUserAsAdmin, cloud_updateUserBrand } from '../utils/mrr/cloudFunctions';
import { Roles } from '../utils/mrr/userConstants';
import { ActionMapType, AuthStateType, AuthUserType, MRRFirebaseContextType } from './types';
// eslint-disable-next-line import/no-cycle
import { apiSlice } from '../redux/rtkQuery/apiSlice';
// eslint-disable-next-line import/no-cycle
import { dispatch as StoreDispatch } from '../redux/store';
import { PATH_ROOT } from '../routes/paths';
import { sendToSentry, sentryAdminActionsFingerprint } from '../utils/mrr/sentryReporter';
import { brandConfig } from '../config';
import countryConfig from '../utils/mrr/phone/countryConfig';


// ----------------------------------------------------------------------

export const RESTORE_GUID_STORED_IN_SESSION_KEY = 'firebase:restoreGUID';
export const ADMIN_STORED_IN_SESSION_KEY = 'firebase:UID';
export const ADMIN_STORED_IN_SESSION_ROLE = 'Firebase:Origin-role'

// ----------------------------------------------------------------------
const verboseLogging = false && CheckLocalDev()

enum Types {
  INITIAL = 'INITIAL',
}

type Payload = {
  [Types.INITIAL]: {
    isInitialized: boolean;
    isAuthenticated: boolean;
    user: AuthUserType;
    role: Roles | null;
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  role: null
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  verboseLogging && console.log('auth context reducer', '\nstate', state, '\naction', action);

  if (action.type === Types.INITIAL) {
    return {
      isInitialized: action.payload.isInitialized,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
      role: action.payload.role
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<MRRFirebaseContextType | null>(null);

// ----------------------------------------------------------------------

export const firebaseApp = initializeApp(FIREBASE_API);

export const AUTH = getAuth(firebaseApp);

export const DB = getFirestore(firebaseApp);

export const CLOUD_FUNCTIONS = getFunctions(firebaseApp)

if (CheckLocalDev()) {
  // These emulators let us use Google Cloud Functions in local dev (to avoid one loop blowing our budget).
  const urlBase = 'localhost'
  const authPort = 3003
  const firestorePort = 3002
  const functionsPort = 3000

  connectAuthEmulator(AUTH, `http://${urlBase}:${authPort}`)

  connectFirestoreEmulator(DB, urlBase, firestorePort)

  connectFunctionsEmulator(CLOUD_FUNCTIONS, urlBase, functionsPort)
}

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [emailVerified, setEmailVerified] = useState(true);
  const [fbToken, setFBToken] = useState('');
  const [adminSessionChanging, setAdminSessionChanging] = useState(false);
  const logout = useCallback(async () => {
    //[[USER LOGOUT]]
    await signOut(AUTH)
      .then((x: any) => {
        verboseLogging && console.log('context signOut THEN', x);
      })
      .catch((y: any) => {
        verboseLogging && console.log('context signOut CATCH', y);
      });
  }, []);

  //NOTE: This watch is important for multi-brand users.
  //      When you change brands, by signing in to another brand's site as the same user, you can
  //      get into a bad state. Specifically, when your token is refreshed, it will have the claims
  //      from the _latest_ brand. The claims might not match the host.
  //      In that scenario, you will get results from the wrong brand. This is confusing, and can
  //      be an access issue with admin mode. Superusers from certain brands are not supposed to
  //      have access to other brands.
  const watchTokenChange = useCallback(() => {
    // Listen for ID token changes
    onIdTokenChanged(AUTH, async (user) => {
      const unsubscribe = onIdTokenChanged(AUTH, async (tokenUser) => {
        if (tokenUser) {
          try {
            const idToken = await tokenUser.getIdToken();
            verboseLogging && console.log('token refreshed:', idToken);

            const idTokenResult = await tokenUser.getIdTokenResult();
            verboseLogging && console.log('token claims:', idTokenResult.claims);

            const validHost = checkClaimsForHost(idTokenResult.claims.brand_code);
            if (!validHost) {
              //NOTE: We could update the user's claims here, to this host's brand.
              //      Since this feature only really affects admins, we'll hold off.
              //      It could get problematic, and logging out is nice and clean.
              console.warn('session invalid for host; logging out');
              //[[USER LOGOUT]]
              await logout();
              window.location.href = PATH_ROOT;
            }
          } catch (error) {
            verboseLogging && console.error('Error in ID token watch:', error);
          }
        }
      });
    });
  }, [logout]);

  const initialize = useCallback(() => {
    try {
      const unsubscribe = onAuthStateChanged(AUTH, async (firebaseUser) => {
        verboseLogging && console.log('onAuthStateChanged', firebaseUser);

        if (firebaseUser) {
          const newFBToken = await firebaseUser.getIdToken();
          setFBToken(newFBToken);

          // OBSOLETE: We no longer require email verification, due to sign-in links.
          // setEmailVerified(firebaseUser.emailVerified);

          const userRef = doc(DB, 'users', firebaseUser.uid);

          const docSnap = await getDoc(userRef);

          if (!docSnap || !docSnap.exists()) {
            //NOTE: In this case, the Firestore record has been deleted (or something else
            //      is keeping us from fetching it). The user _is_ authorized with a valid
            //      Firebase session, so We'll send them to a 'fix your profile info' page.

            verboseLogging && console.warn('Firestore document does not exist on valid login.');

            //[[USER LOGOUT]]
            logout();
            return;
          }

          // get the user's Firestore record
          const profile = docSnap.data();

          if (!profile) {
            throw new Error('Firestore profile not found on valid login.');
          }

          //NOTE: We need to check whether brand claims are set, and if not, post them here.
          //      This is because a user could arrive via an email with a sign-in link (from our admin tools).
          await firebaseUser.getIdTokenResult()
            .then(async (idTokenResult) => {
              const { claims } = idTokenResult;
              if (!claims.brand_code || !claims.brand_id) {
                const brandRequest = httpsCallable(CLOUD_FUNCTIONS, cloud_updateUserBrand);
                await brandRequest({  email: firebaseUser.email || '',
                                      phone: firebaseUser.phoneNumber || '',
                                      brand: brandConfig.brandCode, postAuth: true });
              }
            })
            .catch((error) => {
              console.error('Error getting ID token result:', error);
            });

          const userPayload = InstantiateUserFromJSON({
            ...profile,
            user: firebaseUser,
          })

          userPayload.SetFirebaseUser(firebaseUser);

          const currentRole = userPayload.role;

          dispatch({
            type: Types.INITIAL,
            payload: {
              isInitialized: true,
              isAuthenticated: true,
              user: userPayload,
              role: currentRole as Roles
            },
          });
          setAdminSessionChanging(false)
        } else {
          dispatch({
            type: Types.INITIAL,
            payload: {
              isInitialized: true,
              isAuthenticated: false,
              user: null,
              role: null
            },
          });
        }
      });
      return () => {
        verboseLogging && console.log('unsubscribe from onAuthStateChanged')

        unsubscribe()
      }
    } catch (error) {
      verboseLogging && console.error(error);
      return error
    }
  }, [logout]);

  useEffect(() => {
    watchTokenChange();
    return initialize();
  }, [initialize, watchTokenChange]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(AUTH, email, password);
    }
    catch (e: any) {
      verboseLogging && console.error('login error', e);
      throw e;
    }
  }, []);

  // REGISTER
  const register = useCallback(
    //NOTE: Register is not used in this portal yet; no self service sign-up.
    async (email: string, password: string) => { }, []
  );

  const createUser = useCallback(
    async (
      email: string,
      role: Roles,
      requireVerify: boolean,
      firstName: string = '',
      lastName: string = '',
      fullModel: Record<string, string | number | boolean> | null = null) => {

      const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_createUserAsAdmin);

      const data = {
        email,
        role,
        requireVerify,
        firstName,
        lastName,
      }

      if (fullModel) {
        // this field is reinterpreted for create
        fullModel.requireVerify = fullModel.isVerified;

        // ensure the redundant fields match in full-model create mode
        if (fullModel.email !== email
          || fullModel.role !== role
          || fullModel.requireVerify !== requireVerify
          || fullModel.nameFirst !== firstName
          || fullModel.nameLast !== lastName) {
          throw new Error('Inconsistent user values.'); // this is public
        }
        // send up countries by code, not label (NOTE: We need labels that work with Minimal's dropdowns)
        if (typeof fullModel.country === 'string') {
          const modelCountry = fullModel.country.toLowerCase();
          const searchedCountry = countryConfig.find((country) => country.code.toLowerCase() === modelCountry || country.name.toLowerCase() === modelCountry)
          if (searchedCountry) {
            // ...replace it with the label's code
            fullModel.country = searchedCountry.code;
          } else {
            fullModel.country = '';
          }
        } else {
          fullModel.country = '';
        }
      }

      const cloudResult = cloudFunction({...(fullModel !== null ? fullModel : data), brand: brandConfig.brandCode })
        .then(async (result) => {
          verboseLogging && console.log('admin create user result', result);
          return result;
        })
        .catch((error) => {
          verboseLogging && console.error('admin create user error', error);

          //NOTE: In this portal there is no self-service user create. That can only be done by admins.
          //      If that changes, we may not want to report all of these errors to Sentry.
          sendToSentry(error, null, sentryAdminActionsFingerprint, cloud_createUserAsAdmin);

          throw error;
        });

      return cloudResult;
    },
    []
  );


  const updateUser = useCallback((updatedUserModel: UserAccountGeneral) => {
    verboseLogging && console.log('update session user: ' + updatedUserModel);

    dispatch({
      type: Types.INITIAL,
      payload: {
        isInitialized: true,
        isAuthenticated: true,
        user: updatedUserModel,
        role: updatedUserModel.role
      },
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      role: state.role,
      method: 'firebase',
      login,
      logout,
      register,
      // MRR additions
      emailVerified,
      createUser,
      updateUser,
      fbToken,
      setFBToken,
      adminSessionChanging,
      setAdminSessionChanging
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      state.role,
      login,
      logout,
      register,
      // MRR additions
      emailVerified,
      createUser,
      updateUser,
      fbToken,
      setFBToken,
      adminSessionChanging,
      setAdminSessionChanging
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

//---------------------------------
const TOO_MANY_ATTEMPTS_MESSAGE = 'An email has already been sent.' //[I18N]
const OPERATION_NOT_ALLOWED_MESSAGE = 'There was a problem. Please contact the Guest Experience team.' //[I18N]
const PROBLEM_SENDING_EMAIL_VERIFICATION_MESSAGE = 'There was a problem sending your verification email. Please try again.' //[I18N]
const NOT_PERFORMABLE_OPERATION_MESSAGE = 'This operation is not performable in this context.'
function handleSendEmailVerificationError(errorCode: FirebaseError['code'], setError?: (msg: string) => void) {
  switch (errorCode) {
    case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
      if (typeof setError === 'function') {
        setError(TOO_MANY_ATTEMPTS_MESSAGE)
      }
      throw new Error(TOO_MANY_ATTEMPTS_MESSAGE)
    case AuthErrorCodes.OPERATION_NOT_ALLOWED:
      if (typeof setError === 'function') {
        setError(OPERATION_NOT_ALLOWED_MESSAGE)
      }
      throw new Error(OPERATION_NOT_ALLOWED_MESSAGE)
    default:
      if (typeof setError === 'function') {
        setError(PROBLEM_SENDING_EMAIL_VERIFICATION_MESSAGE)
      }
      throw new Error(PROBLEM_SENDING_EMAIL_VERIFICATION_MESSAGE)
  }
}

export async function beginFirebaseEmailVerification(firebaseUser: User,
  setError?: (msg: string) => void,
  targetUser: null | User = null) {
  if (targetUser === null) {
    if (!firebaseUser || firebaseUser.emailVerified) {
      throw new Error(NOT_PERFORMABLE_OPERATION_MESSAGE)
    }
  }
  else if (targetUser?.emailVerified) {
    throw new Error(NOT_PERFORMABLE_OPERATION_MESSAGE)
  }
  const url = new URL(window.location.href)
  url.pathname = PATH_ROOT
  const actionCodeSettings: ActionCodeSettings = {
    url: url.href,
    handleCodeInApp: true
  };
  sendEmailVerification(targetUser ? targetUser : firebaseUser, actionCodeSettings)
    .then(() => {
      verboseLogging && console.log('Firebase email-verification sent for unverified account')
    })
    .catch((error: FirebaseError) => {
      verboseLogging && console.log('send email error', error);
      return handleSendEmailVerificationError(error.code, setError);
    });
}

export function removeSessionAdmin() {
  window.sessionStorage.removeItem(ADMIN_STORED_IN_SESSION_KEY);
  window.sessionStorage.removeItem(ADMIN_STORED_IN_SESSION_ROLE);
  window.sessionStorage.removeItem(RESTORE_GUID_STORED_IN_SESSION_KEY);

  // wipe the RTK Query cache
  StoreDispatch(apiSlice.util.resetApiState());
}
