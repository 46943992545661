// @mui
import { Dialog, Button, DialogTitle, DialogActions, DialogContent, Stack } from '@mui/material';
//
import { ConfirmDialogProps } from './types';

// ----------------------------------------------------------------------

export default function ConfirmDialog({
  title,
  content,
  action,
  open,
  onClose,
  enableCancelButton = true,
  hideCancel = false,
  ...other
}: ConfirmDialogProps) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      {title && <DialogTitle sx={{ pb: content ? 2 : 0 }}>{title}</DialogTitle>}

      {content && <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>}

      <DialogActions>
        <Stack direction="row" justifyContent="space-between" flexGrow={1}>
          {!hideCancel && (
            <Button variant="text" color="primary" disabled={!enableCancelButton} onClick={onClose}>
              Cancel
            </Button>
          )}
          {action}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
