import { signInWithCustomToken } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { apiSlice } from 'src/redux/rtkQuery/apiSlice';
import { dispatch } from 'src/redux/store';
import { brandConfig } from 'src/config';
import { ADMIN_STORED_IN_SESSION_KEY, ADMIN_STORED_IN_SESSION_ROLE, AUTH, CLOUD_FUNCTIONS, RESTORE_GUID_STORED_IN_SESSION_KEY, removeSessionAdmin } from '../../../auth/FirebaseContext';
import { cloud_signInAsOtherUser } from '../cloudFunctions';
import { safelyReadErrorMessage } from '../errorHandling';
import { Roles } from '../userConstants';
import { sendToSentry, sentryAdminActionsFingerprint } from '../sentryReporter';

//--------------------------------------
function saveSessionAdmin(uid: string, restoreGUID: string, role?: Roles) {
    window.sessionStorage.setItem(ADMIN_STORED_IN_SESSION_KEY, uid)
    if (role) {
        window.sessionStorage.setItem(ADMIN_STORED_IN_SESSION_ROLE, role)
    }
    window.sessionStorage.setItem(RESTORE_GUID_STORED_IN_SESSION_KEY, restoreGUID)
}
/**
 * @param restoreMode tells the function to switch back to original user
 * @param restoreGUID required only in restoreMode
 */
export default async function signInAsOtherUser(restoreMode: boolean, currentUserId: string, targetUserId: string, restoreGUID?: string, role?: Roles) {
    if (!targetUserId) {
        throw new Error('Please provide a valid target user id')
    }
    if (!currentUserId) {
        throw new Error('Please provide a valid current user id')
    }
    if (restoreMode && !restoreGUID) {
        throw new Error('In restore mode, the restoreGUID is required.')
    }

    const _getSignedInUserToken = httpsCallable<Cloud_Function_Payload, Cloud_Function_Success>(CLOUD_FUNCTIONS, cloud_signInAsOtherUser)

    const payload: Cloud_Function_Payload = {
        brand: brandConfig.brandCode,
        userId: targetUserId,
        ...(restoreMode && { restoreGUID })
    }

    const responseData = await _getSignedInUserToken(payload)

    if (!responseData.data) {
        throw new Error('Change-user response missing');
    }
    if (!responseData.data.success) {
        sendToSentry(null,
            'Sign in as other failed; current: ' + currentUserId + ' target: ' + targetUserId,
            sentryAdminActionsFingerprint,
            cloud_signInAsOtherUser);

        throw new Error('There was a problem signing in as selected user.')
    }

    const userTokenToSignInAs = responseData.data.token;
    const userTokenToSaveToSessionStorage = responseData.data?.restoreGUID

    if (!restoreMode) {
        saveSessionAdmin(currentUserId, userTokenToSaveToSessionStorage, role)
    }

    signInWithCustomToken(AUTH, userTokenToSignInAs).catch((error) => {
        throw new Error(safelyReadErrorMessage(null, error))
    })
    // wipe the RTK Query cache
    dispatch(apiSlice.util.resetApiState());
}

export async function logOutOfOtherUser() {
    const restoreAdmin = getSessionAdmin()
    const restoreGUID = getSessionGUID()

    if (!restoreAdmin) {
        throw new Error('Failed to find existing Admin account.')
    }
    if (!restoreGUID) {
        throw new Error('Failed to find existing GUID.')
    }

    await signInAsOtherUser(true, restoreAdmin, restoreAdmin, restoreGUID).then(() => {
        removeSessionAdmin()
    }).catch((error) => {
        throw new Error(safelyReadErrorMessage(null, error))
    })
}

export function hasSessionAdmin() {
    let exist = false
    for (let i = 0; i <= window.sessionStorage.length; i++) {
        if (window.sessionStorage.key(i) === ADMIN_STORED_IN_SESSION_KEY) {
            exist = true
        }
    }
    return exist
}

//--------------------------------------

export interface Cloud_Function_Success extends Record<string, any> {
    success: boolean
}

export interface Cloud_Function_Payload {
    brand: string;
    userId: string;
    guid?: string;
}//-------------------------------

export function getSessionAdmin() {
    return window.sessionStorage.getItem(ADMIN_STORED_IN_SESSION_KEY)
}
export function getSessionGUID() {
    return window.sessionStorage.getItem(RESTORE_GUID_STORED_IN_SESSION_KEY)
}
export function getSessionRole() {
    return window.sessionStorage.getItem(ADMIN_STORED_IN_SESSION_ROLE)
}
