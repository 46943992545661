import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import FormProvider, { RHFCheckbox } from 'src/components/hook-form';
import { ReservationGeneral } from 'src/models/ReservationGeneral';
import { usePatchRegistrationStepMutation } from 'src/redux/rtkQuery/apiSlice';
import * as Yup from 'yup';
import { RegistrationPicklistItem } from './types';
import { useSnackbar } from 'notistack';

const resolver = yupResolver(
  Yup.object().shape({
    resort_disclosures: Yup.boolean().oneOf([true], '').required(''),
  })
);

const RegistrationResortDisclosureForm = ({
  reservation,
  stepCompleted,
  callbackCancel,
  callbackContinue,
}: {
  reservation: ReservationGeneral;
  stepCompleted: boolean;
  callbackCancel: VoidFunction;
  callbackContinue: VoidFunction;
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [sendPatchRegistrationStep] = usePatchRegistrationStepMutation();
  const { resort_disclosures } = reservation;
  const methods = useForm({
    resolver,
    defaultValues: { resort_disclosures: stepCompleted },
    mode: 'all',
  });
  const {
    handleSubmit,
    formState: { isSubmitting, isLoading, isValid },
  } = methods;
  const onSubmit = handleSubmit(async (formData) => {
    if (stepCompleted) {
      // Step is already completed, no need to update
      // Exit and proceed to next step
      callbackContinue();
      return;
      // Updates detected, should post updates
    }
    const sfPayload = {
      reservationName: reservation.name,
      registrationStep: RegistrationPicklistItem.RESORT_DISCLOSURES,
    };
    await sendPatchRegistrationStep(sfPayload)
      .then((data: any) => {
        if (data.error) {
          console.error(data.error.message);
          return;
        }
        enqueueSnackbar('Resort disclosures completed.', { variant: 'success' });
        callbackContinue();
      })
      .catch((putError: any) => {
        console.error('error on update stay reason', putError);
      });
  });
  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Divider sx={{ mb: 2 }} />
      <Stack direction="column" spacing={2}>
        {Object.keys(resort_disclosures).map((key) => {
          return (
            <div key={key + '-disclosure'}>
              <Typography variant="h6">{key}</Typography>
              <Typography variant="body1">{resort_disclosures[key]}</Typography>
            </div>
          );
        })}
      </Stack>
      <Box pt={3}>
        <RHFCheckbox
          disabled={isSubmitting}
          labelPlacement="end"
          name="resort_disclosures"
          label={
            <Typography variant="subtitle2" pl={0.5}>
              I acknowledge resort disclosures
            </Typography>
          }
          sx={{ ml: 0 }}
          onChange={onSubmit}
        />
      </Box>
      {/* Footer buttons */}
      <Stack direction="row" justifyContent="space-between" width="100%" mt={3}>
        <Button disabled={isSubmitting} onClick={callbackCancel}>
          Back
        </Button>
        <LoadingButton type="submit" loading={isSubmitting} disabled={!isValid} variant="contained">
          Continue
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};

export default RegistrationResortDisclosureForm;
