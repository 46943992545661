import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Stack, Typography } from '@mui/material';
import { FirebaseError } from 'firebase/app';
import { CountryCode } from 'libphonenumber-js';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RHFAutocomplete, RHFRadioGroup, RHFTextField } from 'src/components/hook-form';
import * as Yup from 'yup';
import { GAEventGuest, useAnalyticsContext } from '../../analytics';
import FormProvider from '../../components/hook-form/FormProvider';
import Iconify from '../../components/iconify';
import {
  GuestGeneral,
  GuestRelation,
  GuestRelationOptions,
  GuestType,
  GuestTypeOptions,
} from '../../models/GuestGeneral';
import { ReservationGeneral } from '../../models/ReservationGeneral';
import {
  usePostReservationGuestMutation,
  usePutReservationGuestMutation,
} from '../../redux/rtkQuery/apiSlice';
import {
  checkAgeValid,
  convertInputTextToInt,
  handleIntegerInputChange,
  MINOR_AGE,
} from '../../utils/mrr/ageUtils';
import {
  cloud_postReservationGuest,
  cloud_putReservationGuest,
} from '../../utils/mrr/cloudFunctions';
import { extractCountryConfig, validatePhoneNumber } from '../../utils/mrr/phone/phone';
import { EMAIL_REGEX } from '../../utils/mrr/uiConstants';
import { yupCheckPhone } from '../account/userSchemes';
import { StandardRTKQError } from '../error/StandardRTKQError';

const EMAIL_ERROR =
  'This email belongs to an account with different contact information. Please check the spelling or provide a different name to add this guest.';

interface FormProps {
  reservation_name: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  age: string;
  relation: GuestRelation | null;
  type: GuestType;
}

const defaultGuestType = GuestType.Guest;

const resolver = Yup.object<FormProps>().shape({
  first_name: Yup.string().required('Guest first name is required.'),
  last_name: Yup.string().required('Guest last name is required.'),
  age: Yup.number()
    .typeError('Please enter a valid age in years.')
    .required('Please enter an age in years.')
    .when('type', {
      is: GuestType.Host,
      then: (schema) => schema.min(MINOR_AGE, 'Hosts must be at least 18 years old.'),
    }),
  relation: Yup.string().oneOf(GuestRelationOptions).nullable(),
  type: Yup.string()
    .oneOf(GuestTypeOptions, { message: 'Please select a valid guest type.' })
    .required('Please do not leave this field blank.'),
  phone: Yup.string().test((value, context) => {
    if (value) {
      return yupCheckPhone(value, context);
    }
    return true;
  }),
  email: Yup.string().test((value, context) => {
    if (context.parent.type !== GuestType.Host) {
      if (value === '') {
        // guest email may be empty
        return true;
      }
    }
    const valid = EMAIL_REGEX.test(value || '');
    if (valid) {
      return true;
    }
    throw new Yup.ValidationError('Please provide a valid email.', value, context.path, 'guest');
  }),
});

export function AddGuestForm({
  reservationName,
  callbackCancel,
  callbackSubmit,
  guestToEdit,
  gutterBottom = true,
}: {
  reservationName: ReservationGeneral['name'];
  callbackCancel: VoidFunction;
  callbackSubmit: VoidFunction;
  guestToEdit?: GuestGeneral | null;
  gutterBottom?: boolean;
}) {
  const [globalError, setGlobalError] = useState<string | null>(null);
  const [ageIsMinor, setAgeIsMinor] = useState(false);

  const methods = useForm<FormProps>({
    defaultValues: {
      email: guestToEdit?.email || '',
      first_name: guestToEdit?.first_name || '',
      last_name: guestToEdit?.last_name || '',
      phone: guestToEdit?.phone || '',
      age: guestToEdit?.age ? `${guestToEdit.age}` : '',
      relation: guestToEdit?.relation || null,
      type: guestToEdit?.type || defaultGuestType,
    },
    resolver: yupResolver(resolver),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, errors, isDirty },
    trigger,
    setError,
    getFieldState,
    setValue,
    watch,
  } = methods;

  const [
    sendPostReservationGuest,
    { isError: addGuestIsError, error: addGuestError, reset: resetAddGuestMutation },
  ] = usePostReservationGuestMutation();
  const [sendPutReservationGuest, { isError: updateGuestIsError, error: updateGuestError }] =
    usePutReservationGuestMutation();

  const { sendEvent } = useAnalyticsContext();

  const { email: emailError } = errors;

  useEffect(() => {
    if (emailError && addGuestIsError) {
      setGlobalError(EMAIL_ERROR);
    } else if (!emailError) {
      resetAddGuestMutation();
      setGlobalError(null);
    }
  }, [emailError, resetAddGuestMutation, addGuestIsError]);

  const onSubmit = handleSubmit(
    async ({ email, first_name, last_name, phone, age, relation, type }) => {
      const sfRelation = type === GuestType.Host ? undefined : relation;
      const isMinor = type === GuestType.Host ? false : ageIsMinor;

      const sfPayload = {
        reservationName, // for cache tagging, only
        type: type,
        first_name,
        last_name,
        is_minor: isMinor,
        age,
        // Optional Fields
        ...(sfRelation && { relation: sfRelation }),
        ...(email && { email }),
        ...(phone && { phone }),
      };

      if (phone) {
        const countryConfig = extractCountryConfig(phone);
        let sfPhone = '';
        const phoneResult = validatePhoneNumber(phone || '', countryConfig.code as CountryCode);
        if (phoneResult.success) {
          sfPhone = phoneResult.parsedPhone.formatInternational();
        }
        if (sfPhone) {
          sfPayload.phone = sfPhone;
        }
      }

      const usePUT = !!guestToEdit;

      if (usePUT) {
        if (!isDirty) {
          callbackSubmit && callbackSubmit();
        }
        (sfPayload as any).guestId = guestToEdit?.id;

        // UPDATE EXISTING GUEST
        await sendPutReservationGuest(sfPayload)
          .then((data: any) => {
            if (data.error) {
              setGlobalError(data.error.message);
              return;
            }
            callbackSubmit();
          })
          .catch((putError: any) => {
            console.error("error on update add'l guest", putError);
          });
        return;
      }
      // CREATE NEW GUEST
      await sendPostReservationGuest(sfPayload)
        .then((data: any) => {
          if (!data.error) {
            sendEvent(
              new GAEventGuest(
                sfPayload.type,
                sfPayload.is_minor,
                sfPayload.first_name,
                sfPayload.last_name,
                true,
                sfPayload.relation,
                reservationName
              )
            );
            callbackSubmit();
          }
          if (
            data.error instanceof FirebaseError &&
            data.error.code === 'functions/already-exists'
          ) {
            setError('email', {
              message: 'This email belongs to an account with different contact information.',
            });
            setGlobalError(EMAIL_ERROR);
          } else if (data.error) {
            console.warn('non-email error on create guest', data.error);
            setGlobalError(data.error.message);
          }
        })
        .catch((createError: any) => {
          console.error('error on create guest', createError);
        });
    }
  );

  const { type, age } = watch();
  const isGuest = type === GuestType.Guest;
  const firstNameState = getFieldState('first_name');
  const lastNameState = getFieldState('last_name');

  useEffect(() => {
    if (ageIsMinor) {
      setValue('email', '');
      setValue('phone', '');
    }
  }, [type, setValue, ageIsMinor]);

  useEffect(() => {
    const parsedAge = parseInt(age || '', 10);
    const inputAgeValid = checkAgeValid(parsedAge);
    if (inputAgeValid) {
      if (ageIsMinor !== parsedAge < MINOR_AGE) {
        setAgeIsMinor(parsedAge < MINOR_AGE);
      }
    }
  }, [age, ageIsMinor]);

  //NOTE: 'Host' is not a valid option, but for legacy reasons it can be set on the guest.
  //      If it is set, it breaks the form (even though it's optional), because it's not
  //      one of the drop-down options.
  //      We poke this at startup, to show Yup instructions on the input in this case.
  useEffect(() => {
    if (
      guestToEdit &&
      guestToEdit.relation &&
      (guestToEdit.relation as any).toLowerCase() === 'host'
    ) {
      trigger('relation');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const remainingStates: string[] = [];

  if (!(firstNameState.isDirty || firstNameState.isTouched) || firstNameState.invalid) {
    remainingStates.push('First Name');
  }
  if (!(lastNameState.isDirty || lastNameState.isTouched) || lastNameState.invalid) {
    remainingStates.push('Last Name');
  }

  const showStandardError = (addGuestError || updateGuestIsError) && !globalError;
  const showGlobalError = globalError;

  return (
    <FormProvider
      name={guestToEdit ? 'Update Guest Form' : 'Add Guest Form'}
      id={`${guestToEdit ? 'Update' : 'Add'} Guest Form: ${reservationName}`}
      methods={methods}
      onSubmit={onSubmit}
    >
      <Stack spacing={2} sx={{ pt: 3 }}>
        {showStandardError &&
          (addGuestIsError ? (
            <StandardRTKQError
              error={addGuestError}
              endpoint={cloud_postReservationGuest}
              mutationCall
            />
          ) : (
            <StandardRTKQError
              error={updateGuestError}
              endpoint={cloud_putReservationGuest}
              mutationCall
            />
          ))}
        {showGlobalError && <Alert severity="error">{globalError}</Alert>}
        <Stack spacing={1} direction="row">
          <RHFTextField aria-required required name="first_name" label="First Name" />
          <RHFTextField aria-required required name="last_name" label="Last Name" />
        </Stack>
        <RHFTextField
          aria-required
          name="age"
          label="Age"
          required
          onChange={(e) => {
            const parsedValue = handleIntegerInputChange(e);
            setValue('age', convertInputTextToInt(parsedValue));
            // needed for as-you-type updates, due to our custom Yup rule callback
            trigger('age');
          }}
        />
        <RHFRadioGroup
          spacing={2}
          sx={{ width: '100%' }}
          label={
            <Typography color="rgb(33, 43, 54)" variant="h6" mb={1}>
              Guest Type
            </Typography>
          }
          name="type"
          onChange={(e) => {
            setValue('type', e.currentTarget.value as GuestType);
            trigger('age');
          }}
          options={GuestTypeOptions.map((t) => ({
            label:
              t === GuestType.Guest ? (
                <Typography variant="body2">
                  <b>Guest:</b> All overnight guests must be listed on the reservation. Guests with
                  an email address can access reservation information via the guest portal.
                </Typography>
              ) : (
                <Typography variant="body2">
                  <b>Host:</b> Hosts can access all reservation information via the guest portal,
                  order services, make payments and manage the guest list.
                </Typography>
              ),
            value: t,
          }))}
        />
        <Stack spacing={0.75}>
          <RHFTextField
            required={!isGuest}
            aria-required={!isGuest}
            label={!isGuest ? 'Email' : 'Email (Optional)'}
            name="email"
            type="email"
          />
          <Stack alignItems="flex-start" direction="row" spacing={0.5}>
            <Iconify icon="flat-color-icons:info" width={16} />
            <Typography fontSize={12} variant="caption" color="text.secondary">
              To grant access to the guest portal, please provide an email address.
            </Typography>
          </Stack>
        </Stack>
        {/* <Divider />
        <Typography variant="h6" mt={2}>
          Optional guest information.
        </Typography> */}
        <RHFAutocomplete
          fullWidth
          aria-required
          name="relation"
          options={GuestRelationOptions}
          label="Relation (Optional)"
        />
        <Stack spacing={0.75}>
          <RHFTextField name="phone" type="phone" label="Phone (Optional)" />
          <Stack alignItems="flex-start" direction="row" spacing={0.5}>
            <Iconify icon="flat-color-icons:info" width={16} />
            <Typography fontSize={12} variant="caption" color="text.secondary">
              By providing phone number, guest agrees to receive reservation updates.
            </Typography>
          </Stack>
        </Stack>
      <Typography variant="caption">* Required fields</Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        mt={3}
        mb={gutterBottom ? 3 : 0}
      >
        <Button disabled={isSubmitting} onClick={callbackCancel}>
          Cancel
        </Button>
        <LoadingButton type="submit" loading={isSubmitting} disabled={!isValid} variant="contained">
          {(guestToEdit ? 'Update' : 'Add') + ' Guest'}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}

export function createLabelValueOption(label: string, value: any) {
  return { label, value };
}
