// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { SxProps, TextField, TextFieldProps, Theme } from '@mui/material';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  readOnly?: boolean;
  hideError?: boolean;
};

export default function RHFTextField({
  name,
  helperText,
  readOnly,
  hideError = false,
  sx,
  ...other
}: Props) {
  const { control } = useFormContext();
  const style: SxProps<Theme> = { ...sx, ...(readOnly && { pointerEvents: 'none' }) };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          sx={style}
          InputProps={{ readOnly }}
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          error={hideError ? false : !!error}
          helperText={error && !hideError ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
}
