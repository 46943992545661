// Reservation
export const endpoint_getHybridReservations = 'getHybridReservations';
export const endpoint_getReservations = 'getReservations';
export const endpoint_getReservation = 'getReservation';
export const endpoint_patchExtendStay = 'patchExtendStay';
export const endpoint_patchCheckinRequest = 'patchCheckinRequest';
export const endpoint_patchCheckoutTime = 'patchCheckoutTime';

// Registration
export const endpoint_patchRegistrationStep = 'patchRegistrationStep';

// Case
export const endpoint_getCases = 'getCases';
export const endpoint_postCase = 'postCase';
export const endpoint_postCaseComment = 'postCaseComment';
export const endpoint_postIdentityVerification = 'postIdentityVerification';
export const endpoint_getAttachments = 'getAttachments';

// Flight
export const endpoint_patchFlightInformation = 'patchFlightInformation';

// Guest
export const endpoint_getGuests = 'getGuests';
export const endpoint_postGuest = 'postGuest';
export const endpoint_putGuest = 'putGuest';
export const endpoint_deleteGuest = 'deleteGuest';
export const endpoint_patchPets = 'patchPets';

// Listing
export const endpoint_getListings = 'getListings';
export const endpoint_getListing = 'getListing';

// Portal Info
export const endpoint_getPortalInfo = 'getPortalInfo';

// Revenue
export const endpoint_getRevenue = 'getRevenue';

// Review
export const endpoint_getReview = 'getReview';
export const endpoint_postReview = 'postReview';
export const endpoint_putReview = 'putReview';

// ROI
export const endpoint_getAdditionalROIs = 'getAdditionalROIs';
export const endpoint_getROIs = 'getROIs';
export const endpoint_postROIs = 'postROIs';
export const endpoint_postTIQuote = 'postTIQuote';
export const endpoint_postLateCheckout = 'postLateCheckout';
