import { ReservationGeneral } from 'src/models/ReservationGeneral';

export enum RegistrationPicklistItem {
  VERIFY_HOST = 'VERIFY_HOST',
  GUEST_LIST = 'GUEST_LIST',
  STAY_REASON = 'STAY_REASON',
  VEHICLE_REGISTRATION = 'VEHICLE_REGISTRATION',
  SERVICE_ANIMAL = 'SERVICE_ANIMAL',
  RESORT_DISCLOSURES = 'RESORT_DISCLOSURES',
  RENTAL_AGREEMENT = 'RENTAL_AGREEMENT',
  ID_VERIFICATION = 'ID_VERIFICATION',
}

export enum RegistrationWizardStep {
  Greeting,
  MainGuest,
  AdditionalGuests,
  StayReason,
  Vehicles,
  ServiceAnimal,
  ResortDisclosures,
  RentalAgreement,
  IdentityVerification,
  Summary,
  SubChangeHost,
  SubAddGuest,
  SubEditGuest,
  TOTAL,
}

export type WizardStepInfo = {
  key: RegistrationWizardStep;
  order: number;
  title: string;
  instruction: string;
  rendersFooter: boolean;
  subStep: boolean;
  completed: boolean; // set dynamically
  final: boolean; // set dynamically
};
type WizardStepInfoSpec = Record<RegistrationWizardStep, WizardStepInfo>;

const wizardStepInfoSpec: WizardStepInfoSpec = {} as any;

let orderCursor = 0;
wizardStepInfoSpec[RegistrationWizardStep.Greeting] = {
  key: RegistrationWizardStep.Greeting,
  order: orderCursor++,
  title: 'Guest Registration',
  instruction: '',
  rendersFooter: false,
  subStep: false,
  completed: false,
  final: false,
};
wizardStepInfoSpec[RegistrationWizardStep.MainGuest] = {
  key: RegistrationWizardStep.MainGuest,
  order: orderCursor++,
  title: 'Host Verification',
  instruction: 'Please verify that the below host information is accurate and complete.',
  rendersFooter: true,
  subStep: false,
  completed: false,
  final: false,
};
wizardStepInfoSpec[RegistrationWizardStep.AdditionalGuests] = {
  key: RegistrationWizardStep.AdditionalGuests,
  order: orderCursor++,
  title: 'Provide Guest List',
  instruction: 'Please specify all guests that will be staying with the host.',
  rendersFooter: true,
  subStep: false,
  completed: false,
  final: false,
};
wizardStepInfoSpec[RegistrationWizardStep.StayReason] = {
  key: RegistrationWizardStep.StayReason,
  order: orderCursor++,
  title: 'Reason for Stay',
  instruction: 'Please provide the reason for your stay.',
  rendersFooter: true,
  subStep: false,
  completed: false,
  final: false,
};
wizardStepInfoSpec[RegistrationWizardStep.Vehicles] = {
  key: RegistrationWizardStep.Vehicles,
  order: orderCursor++,
  title: 'Vehicle Registration',
  instruction:
    'Please provide the license plate # for each vehicle that will be parked at property.',
  rendersFooter: true,
  subStep: false,
  completed: false,
  final: false,
};
wizardStepInfoSpec[RegistrationWizardStep.ServiceAnimal] = {
  key: RegistrationWizardStep.ServiceAnimal,
  order: orderCursor++,
  title: 'Service Animal',
  instruction: '',
  rendersFooter: true,
  subStep: false,
  completed: false,
  final: false,
};
wizardStepInfoSpec[RegistrationWizardStep.ResortDisclosures] = {
  key: RegistrationWizardStep.ResortDisclosures,
  order: orderCursor++,
  title: 'Resort Disclosures',
  instruction: '',
  rendersFooter: true,
  subStep: false,
  completed: false,
  final: false,
};
wizardStepInfoSpec[RegistrationWizardStep.RentalAgreement] = {
  key: RegistrationWizardStep.RentalAgreement,
  order: orderCursor++,
  title: 'Rental Agreement',
  instruction: 'Review and sign the rental agreement.',
  rendersFooter: true,
  subStep: false,
  completed: false,
  final: false,
};
wizardStepInfoSpec[RegistrationWizardStep.IdentityVerification] = {
  key: RegistrationWizardStep.IdentityVerification,
  order: orderCursor++,
  title: 'Identity Verification',
  instruction: 'Verify your identity.',
  rendersFooter: true,
  subStep: false,
  completed: false,
  final: false,
};
wizardStepInfoSpec[RegistrationWizardStep.Summary] = {
  key: RegistrationWizardStep.Summary,
  order: orderCursor++,
  title: 'Registration Complete',
  instruction: 'Thank you for completing guest registration. You may close this window.',
  rendersFooter: false,
  subStep: false,
  completed: false,
  final: false,
};
// 'hidden' substep of MainGuest
wizardStepInfoSpec[RegistrationWizardStep.SubChangeHost] = {
  key: RegistrationWizardStep.SubChangeHost,
  order: orderCursor++,
  title: 'Change Host',
  instruction: 'Please provide the host information below.',
  rendersFooter: true,
  subStep: true,
  completed: false,
  final: false,
};
// 'hidden' substep of AdditionalGuests
wizardStepInfoSpec[RegistrationWizardStep.SubAddGuest] = {
  key: RegistrationWizardStep.SubAddGuest,
  order: orderCursor++,
  title: 'Add Guest',
  instruction: 'Please provide required guest information.',
  rendersFooter: true,
  subStep: true,
  completed: false,
  final: false,
};
// 'hidden' substep of AdditionalGuests
wizardStepInfoSpec[RegistrationWizardStep.SubEditGuest] = {
  key: RegistrationWizardStep.SubEditGuest,
  order: orderCursor++,
  title: 'Edit Guest',
  instruction: 'Please provide required guest information.',
  rendersFooter: true,
  subStep: true,
  completed: false,
  final: false,
};

const mapPicklistToEnum = (key: string): RegistrationWizardStep | null => {
  switch (key) {
    case 'Greeting':
      return RegistrationWizardStep.Greeting;
    case RegistrationPicklistItem.VERIFY_HOST:
      return RegistrationWizardStep.MainGuest;
    case RegistrationPicklistItem.GUEST_LIST:
      return RegistrationWizardStep.AdditionalGuests;
    case RegistrationPicklistItem.STAY_REASON:
      return RegistrationWizardStep.StayReason;
    case RegistrationPicklistItem.VEHICLE_REGISTRATION:
      return RegistrationWizardStep.Vehicles;
    case RegistrationPicklistItem.SERVICE_ANIMAL:
      return RegistrationWizardStep.ServiceAnimal;
    case RegistrationPicklistItem.RESORT_DISCLOSURES:
      return RegistrationWizardStep.ResortDisclosures;
    case RegistrationPicklistItem.RENTAL_AGREEMENT:
      return RegistrationWizardStep.RentalAgreement;
    case RegistrationPicklistItem.ID_VERIFICATION:
      return RegistrationWizardStep.IdentityVerification;
    case 'Summary':
      return RegistrationWizardStep.Summary;

    default: {
      console.warn('Unknown registration picklist item: ' + key);
      return null;
    }
  }
};

export const getFriendlyPicklistName = (key: string): string | null => {
  switch (key) {
    case RegistrationPicklistItem.VERIFY_HOST:
      return 'Verify host';
    case RegistrationPicklistItem.GUEST_LIST:
      return 'Guest list';
    case RegistrationPicklistItem.STAY_REASON:
      return 'Stay reason';
    case RegistrationPicklistItem.VEHICLE_REGISTRATION:
      return 'Vehicle registration';
    case RegistrationPicklistItem.SERVICE_ANIMAL:
      return 'Service animal registration';
    case RegistrationPicklistItem.RENTAL_AGREEMENT:
      return 'Rental agreement';
    case RegistrationPicklistItem.RESORT_DISCLOSURES:
      return null; // We want to be able to silently submit this step
      // return 'Resort disclosures';
    case RegistrationPicklistItem.ID_VERIFICATION:
      return 'Identity verification';

    default: {
      console.warn('Getting name of unknown registration picklist item: ' + key);
      return 'Registration step';
    }
  }
};

const summaryEnabled = false;

export const buildRegistrationWizardSteps = (reservation: ReservationGeneral, hideResortDisclosures:boolean) => {
  const picklistValues = reservation.guest_registration_requirements || [];
  const completedPicklistValues = reservation.guest_registration_completed || [];
  let spec: WizardStepInfo[] = [];

  // greeting is always included as first
  spec.push(wizardStepInfoSpec[RegistrationWizardStep.Greeting]);

  const dupeChecker = new Set<string>();

  // add the steps required by the reservation
  picklistValues.forEach((k) => {
    const stepEnum = mapPicklistToEnum(k);
    if (stepEnum === null) {
      console.warn('Unknown registration wizard step: ' + k);
      return;
    }
    if (dupeChecker.has(k)) {
      console.warn('Duplicate registration wizard step: ' + k);
      return;
    }
    dupeChecker.add(k);

    // clone the template for this step, to be mutated
    const cloned = { ...wizardStepInfoSpec[stepEnum] };
    if (completedPicklistValues.find((v) => v === k)) {
      cloned.completed = true;
    }
    spec.push(cloned);
  });

  if (hideResortDisclosures) {
    // remove the resort disclosures step
    spec = spec.filter((s) => s.key !== RegistrationWizardStep.ResortDisclosures);
  }

  //NOTE: Order could be driven by the picklist, too.
  //      For that, just drop this sort.
  spec.sort((a, b) => a.order - b.order);

  const finalFormStep = spec[spec.length - 1];
  finalFormStep.final = true;

  if (summaryEnabled) {
    spec.push(wizardStepInfoSpec[RegistrationWizardStep.Summary]);
  }

  //TODO: We could do this dynamically, by looping and check the .substep property.
  // include the 'hidden' substeps
  spec.push(wizardStepInfoSpec[RegistrationWizardStep.SubChangeHost]);
  spec.push(wizardStepInfoSpec[RegistrationWizardStep.SubAddGuest]);
  spec.push(wizardStepInfoSpec[RegistrationWizardStep.SubEditGuest]);
  return spec;
};
