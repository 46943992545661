import { Alert, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../auth/useAuthContext";
import { FullscreenModal } from "../../components/fullscreen-modal/FullscreenModal";
import { GuestGeneral } from "../../models/GuestGeneral";
import { ReservationGeneral } from "../../models/ReservationGeneral";
import { useGetReservationGuestsQuery, usePatchRegistrationStepMutation } from "../../redux/rtkQuery/apiSlice";
import { PATH_APP } from "../../routes/paths";
import { HelpLink } from "../auth/VerifyCredentialForm";
import { RegistrationWizardModal } from "./RegistrationWizardModal";
import { RegistrationPicklistItem, RegistrationWizardStep, WizardStepInfo } from "./types";


interface RegistrationWizardProps {
    reservation: ReservationGeneral,
    registrationSteps: WizardStepInfo[],
    callbackForceGuardRefetch: (overrideBusy: boolean) => void
};

export function RegistrationWizard( { reservation, registrationSteps, callbackForceGuardRefetch }: RegistrationWizardProps) {
    const [ step, setStep ] = useState<number>(0);
    const [ wizardError, setWizardError ] = useState<Error | null>(null);
    const { user } = useAuthContext();
    const navigate = useNavigate();

    const registrationStuck = useMemo(() => {
        const raStep = registrationSteps.find(s => s.key === RegistrationWizardStep.RentalAgreement);
        if (!raStep) {
            console.warn('rental agreement step not found');
            return false;
        }
        if (!raStep.completed) {
            return false;
        }
        for (let i = 0; i < registrationSteps.length; ++i) {
            if (registrationSteps[i].key === RegistrationWizardStep.Greeting) {
                // we never complete the greeting step
                continue;
            }
            if (registrationSteps[i].order > raStep.order) {
                // this step is after RA signing; it can't block registration
                continue;
            }
        }
        return false;
    }, [registrationSteps]);

    const {
        isLoading: isLoadingGuest,
        isSuccess: isSuccessGuest,
        data: dataGuest,
        isError: isErrorGuest,
        error: errorGuest,
        refetch: refetchGuest
    } = useGetReservationGuestsQuery(
        { reservationName: reservation.name }
    );

    const [sendPatchRegistrationStep] = usePatchRegistrationStepMutation();

    useEffect(() => {
      const resortDisclosuresRequired = reservation.guest_registration_requirements.includes(RegistrationPicklistItem.RESORT_DISCLOSURES);
      const completedDisclosures = reservation.guest_registration_completed?.includes(RegistrationPicklistItem.RESORT_DISCLOSURES);
      const hideResortDisclosures = Object.keys(reservation.resort_disclosures).length === 0;
      if (hideResortDisclosures && !completedDisclosures && resortDisclosuresRequired) {
        const sfPayload = {
          reservationName: reservation.name,
          registrationStep: RegistrationPicklistItem.RESORT_DISCLOSURES,
        };
        sendPatchRegistrationStep(sfPayload)
          .then((data: any) => {
            if (data.error) {
              console.error(data.error.message);
            }
          })
          .catch((putError: any) => {
            console.error('error on update stay reason', putError);
          });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isErrorGuest) {
        setWizardError(errorGuest as Error);
    }

    let mainGuest: GuestGeneral | null = null;

    // the session user must be a guest which is a host
    let allowRegistration = false

    if (isSuccessGuest && dataGuest.length > 0) {
            dataGuest.forEach((guest: GuestGeneral) => {
                if (!allowRegistration && user && user.id === guest.guest_id) {
                    // session user is qualified to register this reservation
                    allowRegistration = guest.isHost;
                }

                if (guest.guest_id === reservation.guest_id) {
                    // guest-of-record found
                    mainGuest = guest;
                }
            });
    }

    if (!user) {
        return null;
    }

    const masterLoading = isLoadingGuest;

    const handleClose = () => {
        // closed without finishing; back to the dashboard
        navigate(PATH_APP.app);
    };

    return (
    <>
        {registrationStuck
            ?
                <FullscreenModal
                    showDialog
                    onClose={handleClose}
                    titleText='Guest Registration'
                    maxWidth='sm'
                    fullscreenBreakpoint='sm'
                >
                    <Stack spacing={2}>
                        <Alert severity="error" style={{fontSize: '1.1rem'}}>
                            We are unable to complete registration in the portal.
                            Please contact support for assistance.
                        </Alert>
                        <HelpLink labelText="Registration Support" />
                    </Stack>

                </FullscreenModal>
            : <RegistrationWizardModal
                allowRegistration
                ageRequired
                reservation={reservation}
                mainGuest={mainGuest}
                guestModels={Array.isArray(dataGuest) ? dataGuest : null}
                sessionUser={user}
                registrationSteps={registrationSteps}
                stepIndex={step}
                setStepIndex={setStep}
                loading={masterLoading}
                error={wizardError}
                callbackMainGuestChange={() => {
                    // we need the reservation, since guest__c has changed, as well as the guests
                    callbackForceGuardRefetch(false);
                    refetchGuest();
                }}
                callbackRefreshReservation={(overrideBusy: boolean = false) => {
                    // get the reservation, to let the guard close/open us as needed
                    callbackForceGuardRefetch(overrideBusy);
                }}
                onClose={handleClose}
            />
        }
    </>
    );
}
