import { doc, Firestore, setDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { CLOUD_FUNCTIONS, DB } from 'src/auth/FirebaseContext';
import { AuthUserType } from 'src/auth/types';
import { useAuthContext } from 'src/auth/useAuthContext';
import { BrandConfig } from 'src/config/brandConfig';
import { StripeCustomer } from 'src/models/Stripe';
import { brandConfig } from '../config';
import {
  EndSessionCallback,
  useExpiredSessionErrorToNavigate,
} from '../pages/dashboard/useExpiredSessionErrorToNavigate';
import { cloud_createCustomer, cloud_getCustomer } from '../utils/mrr/cloudFunctions';
import {
  checkStripeErrorExpected,
  sendToSentry,
  sentryStripeFingerprint,
} from '../utils/mrr/sentryReporter';

const useStripeCustomer = () => {
  const { user } = useAuthContext();
  const stripeCustomerId = user?.GetStripeCustomerId(brandConfig.brandCode);
  const [customer, setCustomer] = useState<StripeCustomer>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const handleExpiredSession = useExpiredSessionErrorToNavigate();

  useEffect(() => {
    const fetchOrCreateCustomer = async () => {
      if (!user) return;
      setLoading(true);
      try {
        let stripeCustomer: StripeCustomer | null = null;
        if (stripeCustomerId) {
          stripeCustomer = await getCustomer(stripeCustomerId, handleExpiredSession);
        }
        if (!stripeCustomer || stripeCustomer.deleted) {
          stripeCustomer = await createCustomer(user, handleExpiredSession);
        }
        if (!stripeCustomer) {
          throw new Error('Failed to create customer');
        }
        setCustomer(stripeCustomer);
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };
    fetchOrCreateCustomer();
  }, [stripeCustomerId, user, handleExpiredSession]);

  useEffect(() => {
    const updateUserStripeCustomerId = async () => {
      if (!user || !customer || stripeCustomerId === customer.id) return;
      try {
        await updateUserStripeInfo(user, customer, brandConfig, DB);
      } catch (err) {
        setError(err as Error);
      }
    };
    updateUserStripeCustomerId();
  }, [customer, stripeCustomerId, user, handleExpiredSession]);

  return { customer, loading, error };
};

async function getCustomer(
  stripeCustomerId: string,
  handleExpiredSession: EndSessionCallback
): Promise<StripeCustomer | null> {
  const getCustomerCallable = httpsCallable(CLOUD_FUNCTIONS, cloud_getCustomer);
  const doGetCustomer = async () => {
    return getCustomerCallable({ customerId: stripeCustomerId })
      .then((result: any) => {
        return (result.data as StripeCustomer) || null;
      })
      .catch((e: any) => {
        console.log('error fetching customer by Stripe Id');
        handleExpiredSession(e);
        if (!checkStripeErrorExpected(e, cloud_getCustomer)) {
          sendToSentry(e, null, sentryStripeFingerprint, cloud_getCustomer);
        }
        return null;
      });
  };
  const customer = await doGetCustomer();
  return customer;
}

async function createCustomer(
  user: AuthUserType,
  handleExpiredSession: EndSessionCallback
): Promise<StripeCustomer | null> {
  const createCustomerCallable = httpsCallable(CLOUD_FUNCTIONS, cloud_createCustomer);
  const doCreateCustomer = async () => {
    if (!user) return null;
    return createCustomerCallable({
      name: user.displayName,
      email: user.email,
      phone: user.phoneNumber,
      address: {
        line1: user.address,
        city: user.city,
        state: user.state,
        postal_code: user.zipCode,
        country: user.country,
      },
      metadata: { salesforce_account_id: user.externalAccountID },
    })
      .then((result: any) => {
        return (result.data as StripeCustomer) || null;
      })
      .catch((e) => {
        handleExpiredSession(e);
        sendToSentry(e, null, sentryStripeFingerprint, cloud_createCustomer);
        throw new Error(e.message);
      });
  };
  const customer = await doCreateCustomer();
  return customer;
}

async function updateUserStripeInfo(
  user: AuthUserType,
  stripeCustomer: StripeCustomer,
  brand: BrandConfig,
  db: Firestore
): Promise<void> {
  if (!user) {
    return;
  }
  user.SetStripeCustomerId(brand.brandCode, stripeCustomer.id);
  const updatedUser = user.ExportAsFirestoreJSON();
  const userRef = doc(db, 'users', user.id);
  await setDoc(userRef, updatedUser, { merge: true });
}

export default useStripeCustomer;
