import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import ConfirmDialog from '../../components/confirm-dialog';
import FormProvider from '../../components/hook-form';
import { ReservationGeneral } from '../../models/ReservationGeneral';
import { usePatchRegistrationStepMutation } from '../../redux/rtkQuery/apiSlice';
import { RegistrationPicklistItem } from './types';

const RegistrationServiceAnimalForm = ({
  handleContinue,
  handleNavBack,
  stepCompleted,
  reservation,
}: {
  handleNavBack: VoidFunction;
  handleContinue: VoidFunction;
  stepCompleted: boolean;
  reservation: ReservationGeneral;
}) => {
  const [sendPatchRegistrationStep, { isLoading: patchStepIsLoading }] =
    usePatchRegistrationStepMutation();

  const defaultValues = {
    hasServiceAnimal: reservation.service_animal,
  };

  const methods = useForm<{ hasServiceAnimal: boolean | null }>({ defaultValues });
  const {
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isLoading, isDirty },
  } = methods;

  const hasServiceAnimal = watch('hasServiceAnimal');

  const onSubmit = handleSubmit(async ({ hasServiceAnimal: hasAnimal }) => {
    if (stepCompleted || isDirty) {
      if (hasAnimal === reservation.service_animal) {
        // Step is already completed, no need to update
        // Exit and proceed to next step
        handleContinue();
        return;
        }
        // Updates detected, should post updates
    }

    const sfPayload = {
      reservationName: reservation.name,
      registrationStep: RegistrationPicklistItem.SERVICE_ANIMAL,
      service_animal: hasAnimal,
    };

    await sendPatchRegistrationStep(sfPayload)
      .then((data: any) => {
        if (data.error) {
          console.error(data.error.message);
          return;
        }
        handleContinue();
      })
      .catch((putError: any) => {
        console.error('error on update service animals', putError);
      });
  });
  return (
    <FormProvider
      name="Edit Service Animal Form"
      id={`Edit Service Animal Form: ${reservation.name}`}
      methods={methods}
      onSubmit={onSubmit}
    >
      <Stack spacing={2}>
        <Divider flexItem />
        <Typography variant="h6" mt={2} sx={{ textAlign: 'justify' }}>
          Do you or anyone in your party require a service animal during your stay?
        </Typography>
        <RadioGroup autoFocus aria-label="service animal" name="hasServiceAnimal">
          <FormControlLabel
            checked={hasServiceAnimal === true}
            onChange={() => {
              setValue('hasServiceAnimal', true);
            }}
            control={<Radio />}
            label={
              <Typography component="label" variant="body1">
                <strong>Yes</strong>, a service animal will be required for my stay.
              </Typography>
            }
          />
          <FormControlLabel
            checked={hasServiceAnimal === false}
            onChange={() => {
              setValue('hasServiceAnimal', false);
            }}
            control={<Radio />}
            label={
              <Typography component="label" variant="body1">
                <strong>No</strong>, a service animal is not required.
              </Typography>
            }
          />
        </RadioGroup>
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
          <Button
            disabled={isSubmitting || isLoading || patchStepIsLoading}
            onClick={handleNavBack}
          >
            Back
          </Button>
          <LoadingButton
            form={`Edit Service Animal Form: ${reservation.name}`}
            disabled={isSubmitting || isLoading || patchStepIsLoading}
            loading={isSubmitting || isLoading || patchStepIsLoading}
            type="submit"
            color="primary"
            variant="contained"
          >
            Continue
          </LoadingButton>
        </Stack>
      </Stack>
      <ConfirmDialog
        aria-label="Service Animal Information"
        aria-hidden="false"
        open={hasServiceAnimal === true}
        // title="Service Animal Information"
        title=""
        content={
          <Stack spacing={2}>
            <Alert severity="warning">
              In accordance with local and federal laws, service animals are not considered pets.
              <p>
                A service animal is individually trained to perform specific tasks directly related
                to a person’s disability. These tasks may include guiding a person who is blind,
                alerting someone who is deaf, pulling a wheelchair, alerting and protecting someone
                having a seizure, or performing other duties.
              </p>
            </Alert>
            {/* <Typography variant="caption">
              *By clicking continue, you are confirming that you or someone in your party requires a
              service animal during your stay.
            </Typography> */}
          </Stack>
        }
        onClose={() => setValue('hasServiceAnimal', false)}
        action={
          <LoadingButton
            form={`Edit Service Animal Form: ${reservation.name}`}
            disabled={isSubmitting || isLoading || patchStepIsLoading}
            loading={isSubmitting || isLoading || patchStepIsLoading}
            type="submit"
            color="primary"
            variant="contained"
          >
            Continue
          </LoadingButton>
        }
      />
    </FormProvider>
  );
};

export default RegistrationServiceAnimalForm;
