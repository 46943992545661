import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Alert, Link, Tooltip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FirebaseError } from "firebase/app";
import { signInWithCustomToken, signInWithPhoneNumber } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { formatLastFour } from "src/utils/mrr/formatLastFour";
import * as Yup from 'yup';
import { AUTH, CLOUD_FUNCTIONS } from "../../auth/FirebaseContext";
import { SignInMethod, useLoginFlow } from "../../auth/LoginProvider";
import { brandConfig } from "../../config";
import { cloud_confirmOneTimePassword, cloud_sendSignInLinkEmail, cloud_updateUserBrand } from "../../utils/mrr/cloudFunctions";
import { RHFCodes } from "../hook-form";
import FormProvider from "../hook-form/FormProvider";

type ConfirmCodeProps = {
    back(): void,
}

const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.string().required('Code is required'),
    code2: Yup.string().required('Code is required'),
    code3: Yup.string().required('Code is required'),
    code4: Yup.string().required('Code is required'),
    code5: Yup.string().required('Code is required'),
    code6: Yup.string().required('Code is required'),
});

const defaultValues = {
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    code6: '',
};
const ConfirmCode = ({ back }: ConfirmCodeProps) => {
    const { emailToVerify, signInMethod, phoneToVerify, confirmationResult, setConfirmationResult, recaptcha } = useLoginFlow();
    const rhfMethods = useForm({ defaultValues, resolver: yupResolver(VerifyCodeSchema), mode: 'onChange' })
    const { handleSubmit, formState: { isValid, isSubmitting, isSubmitted }, trigger } = rhfMethods;
    const [error, setError] = useState<string | null>(null);
    const [navigating, setNavigating] = useState(false);
    const [codeResent, setCodeResent] = useState(false);
    const [resendError, setResendError] = useState<string | null>(null);
    const [resendingLink, setResendingLink] = useState(false);
    const submitRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (isValid) {
            submitRef.current?.click()
        }
    }, [isValid])

    const onSubmit = handleSubmit(async (data) => {
        setError(null)
        const code = Object.values(data).join('')
        if (signInMethod === SignInMethod.Email) {
            try {
                const request = httpsCallable<{ email: string, code: string }, { customToken: string, message: string, success: boolean }>(CLOUD_FUNCTIONS, cloud_confirmOneTimePassword);
                const { success, customToken } = (await request({ email: emailToVerify, code })).data;
                if (!success || !customToken) {
                    setError('The entered 6-digit code is invalid. Please try again.');
                    return;
                }
                const emailBrandRequest = httpsCallable(CLOUD_FUNCTIONS, cloud_updateUserBrand);
                await emailBrandRequest({ email: emailToVerify, brand: brandConfig.brandCode });
                await signInWithCustomToken(AUTH, customToken).then(async (userCredential) => {
                    //NOTE: This case is cosmetic. Auth has already happened, and the guards are loading the page.

                    // Keep the spinner active on successful sign in.
                    setNavigating(true);
                })
            } catch (e) {
                setError('Please try again.');
            }
            return;
        }
        if (signInMethod === SignInMethod.Phone) {
            const phoneBrandRequest = httpsCallable(CLOUD_FUNCTIONS, cloud_updateUserBrand);
            await phoneBrandRequest({ phone: phoneToVerify, brand: brandConfig.brandCode });
            if (!confirmationResult) {
                back();
                return;
            }
            try {
                await confirmationResult.confirm(code).then(() => {
                    // Keep the spinner active on successful sign in.
                    setNavigating(true);
                });
            } catch (e) {
                console.warn(e);
                setError('The entered 6-digit code is invalid. Please try again.')
            }
        }
    })

    if (!signInMethod || (signInMethod === SignInMethod.Phone && !confirmationResult)) {
        console.warn('Missing/bad signInMethod or phone result', signInMethod, 'confirmationResult', confirmationResult);
        back();
        return null;
    }

    const handleResendCode = async () => {
        setResendingLink(true);
        setCodeResent(false);
        setResendError(null);
        if (signInMethod === SignInMethod.Email) {
            const payload = { email: emailToVerify, brand: brandConfig.brandCode };
            const response = await httpsCallable(CLOUD_FUNCTIONS, cloud_sendSignInLinkEmail)(payload);
            const data = response.data as any;
            if (!data || !data.email) {
                setResendError('There was a problem with this email. Please try again.')
                setResendingLink(false);
                return;
            }
            setResendingLink(false);
            setCodeResent(true);
            return;
        }
        if (signInMethod === SignInMethod.Phone && recaptcha) {
            await signInWithPhoneNumber(AUTH, phoneToVerify, recaptcha).then(async (nextPayload) => {
                setConfirmationResult(nextPayload);
                setResendingLink(false);
                setCodeResent(true);
            }).catch((e) => {
                setResendingLink(false);
                if (e instanceof FirebaseError && e.code === 'auth/too-many-requests') {
                    setResendError('Too many attempts. Please try again later.')
                    return;
                }
                setResendError('Sorry we could not send you a code. Please try again.')
            }).finally(() => {
            })
        }
    };

    const alertText = signInMethod === SignInMethod.Email
        ? "A login link has been sent to your email. Please follow that link."
        : `A 6-digit code has been sent to your phone ending in ${formatLastFour(phoneToVerify)}. Please enter it here to login.`

    const loading = isSubmitting || navigating;

    return (
        <Stack spacing={2} direction='column'>
            <Alert severity="info">
                {alertText}
            </Alert>
            {signInMethod !== SignInMethod.Email &&
                <>
                    <FormProvider methods={rhfMethods} onSubmit={onSubmit} id='login-code-form'>
                        <RHFCodes onPaste={(_inputs) => {
                            trigger();
                        }} disabled={loading} placeHolder='' fullWidth keyName="code" inputs={['code1', 'code2', 'code3', 'code4', 'code5', 'code6']} onInput={() => { setCodeResent(false); setError(null); setResendError(null) }} />
                        {isValid && error && <Typography variant='caption' color='error'>{error}</Typography>}
                        {!isValid && isSubmitted && <Typography variant='caption' color='error'>Please provide a valid 6-digit code.</Typography>}
                    </FormProvider>
                    <LoadingButton loading={loading} disabled={!isValid || loading} ref={submitRef} type='submit' variant='contained' form='login-code-form'>Continue</LoadingButton>
                </>
            }
            {signInMethod &&
                <Stack direction='column' alignItems='center'>
                    <Typography component='span'>
                       {signInMethod === SignInMethod.Email
                            ? 'Didn\'t get an email? '
                            : 'Didn\'t get a code? '
                        }
                        <Tooltip title={
                            signInMethod === SignInMethod.Email
                                ? 'Resend login email.'
                                : 'Resend code to phone.'}>
                            <Link
                                onClick={async (e) => {
                                    e.preventDefault();
                                    if (loading) {
                                        return;
                                    }
                                    await handleResendCode();
                                }}
                                sx={{ width: 'fit-content', cursor: 'pointer', ...((resendingLink || loading) && { color: 'text.disabled' }) }}>
                                Resend
                            </Link>
                        </Tooltip>
                    </Typography>
                    {resendError && <Typography variant='caption' color='error'>{resendError}</Typography>}
                    {codeResent &&
                        <Typography variant='caption' color='text-secondary'>
                            {signInMethod !== SignInMethod.Email
                                ? 'Phone code resent'
                                : 'Login email resent'
                            }
                        </Typography>
                    }
                </Stack>
            }
            <Link onClick={back} sx={{ width: 'fit-content', cursor: 'pointer' }}>Back</Link>
        </Stack>
    )
};

export default ConfirmCode;