import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import AdminGuard from '../auth/AdminGuard';
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import RegistrationGuard from '../auth/RegistrationGuard';
import RoleBasedGuard from '../auth/RoleBasedGuard';
import { PageErrorGuard } from '../components/error-handling/PageErrorGuard';
import { CheckEnvironment, SupportedEnvironments, WritePostLoginPath } from '../config-global';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import ErrorPage from '../pages/ErrorPage';
import LogOutPage from '../pages/LogOutPage';
import MRRNotVerifiedPage from '../pages/auth/MRRNotVerifiedPage';
import ListingPage from '../pages/listing/ListingPage';
import ListingsPage from '../pages/listing/ListingsPage';
import PaymentConfirmationPage from '../pages/payment/PaymentConfirmationPage';
import { StripePaymentPage } from '../pages/payment/StripePaymentPage';
import SignatureProvider from '../pages/signurature/SignatureNavigationContext';
import { Roles, adminRoleNames } from '../utils/mrr/userConstants';
import {
  ComingSoonPage,
  FaqsPage,
  GeneralAppPage,
  InvoicePage,
  LoginPage,
  LoginSuccessPage,
  MRRVerifiedPage,
  MaintenancePage,
  OobActionLandingPage,
  Page403,
  Page404,
  Page500,
  PermissionDeniedPage,
  ReservationPage,
  ReservationsPage,
  TestPage,
  TravelInsurancePage,
  UserAccountPage,
  UserCreatePage,
  UserEditPage,
  UserListPage,
} from './elements';
import { OOB_ACTION, OOB_EMAIL_VERIFIED, PATH_AUTH } from './paths';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'emulator', // this is a local-dev-only route
      children: [
        { element: <Navigate to={PATH_AUTH.action} replace />, index: true },
        {
          element: <CompactLayout />,
          children: [
            { path: OOB_EMAIL_VERIFIED, element: <MRRVerifiedPage /> },
            { path: OOB_ACTION, element: <OobActionLandingPage /> },
          ],
        },
      ],
    },
    {
      path: 'user',
      children: [
        { element: <Navigate to={PATH_AUTH.action} replace />, index: true },
        {
          element: <CompactLayout />,
          children: [
            { path: OOB_EMAIL_VERIFIED, element: <MRRVerifiedPage /> },
            { path: OOB_ACTION, element: <OobActionLandingPage /> },
          ],
        },
        {
          path: 'login',
          children: [
            {
              index: true,
              element: (
                <GuestGuard>
                  <LoginPage />
                </GuestGuard>
              ),
            },
            {
              path: 'success',
              element: <CompactLayout />,
              children: [
                {
                  index: true,
                  element: <LoginSuccessPage />,
                },
              ],
            },
          ],
        },
        {
          path: 'not-verified',
          element: (
            <AuthGuard>
              <CompactLayout />
            </AuthGuard>
          ),
          children: [
            // from Owner Portal; unused (revisit this is if we bring back unverified users)
            { index: true, element: <MRRNotVerifiedPage /> },
          ],
        },
        {
          element: <CompactLayout />,
          children: [
            { index: true, element: <Navigate to={PATH_AUTH.login} /> },

            // from Owner Portal; these are on the /user route in this app
            // { path: OOB_SET_PASSWORD, element: <OobCreateInitialPasswordPage /> },
            // { path: OOB_FORGOT_PASSWORD, element: <OobChangePasswordPage /> },
            // { path: OOB_ACTION, element: <OobActionLandingPage /> },
            // { path: 'email-verified', element: <MRRVerifiedPage /> },
          ],
        },
        {
          path: 'account',
          element: (
            <AuthGuard>
              <DashboardLayout showNotifications />
            </AuthGuard>
          ),
          children: [{ index: true, element: <UserAccountPage /> }],
        },
      ],
    },

    // Admin Home
    {
      path: 'admin',
      element: (
        <AdminGuard>
          <AuthGuard>
            <RoleBasedGuard hasContent roles={adminRoleNames}>
              <DashboardLayout showNotifications />
            </RoleBasedGuard>
          </AuthGuard>
        </AdminGuard>
      ),
      children: [
        { element: <Navigate to={WritePostLoginPath(Roles.Admin)} replace />, index: true },
        {
          path: 'app',
          element: (
            <PageErrorGuard>
              {' '}
              <GeneralAppPage />{' '}
            </PageErrorGuard>
          ),
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/admin/user/profile" replace />, index: true },
            {
              path: 'list',
              element: (
                <PageErrorGuard>
                  {' '}
                  <UserListPage />{' '}
                </PageErrorGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <PageErrorGuard>
                  {' '}
                  <UserCreatePage />{' '}
                </PageErrorGuard>
              ),
            },
            {
              path: ':sfId/edit',
              element: (
                <PageErrorGuard>
                  {' '}
                  <UserEditPage />{' '}
                </PageErrorGuard>
              ),
            },
            {
              path: 'account',
              element: (
                <PageErrorGuard>
                  {' '}
                  <UserAccountPage />{' '}
                </PageErrorGuard>
              ),
            },
          ],
        },
      ],
    },
    {
      path: 'faqs',
      element: (
        <AuthGuard>
          <DashboardLayout showNotifications />
        </AuthGuard>
      ),
      children: [
        {
          element: (
            <PageErrorGuard>
              {' '}
              <FaqsPage />{' '}
            </PageErrorGuard>
          ),
          index: true,
        },
      ],
    },
    // {
    //   path: 'demo',
    //   element: (
    //     <AuthGuard>
    //       <DeveloperGuard>
    //         <DashboardLayout showNotifications />
    //       </DeveloperGuard>
    //     </AuthGuard>
    //   ),
    //   children: [
    //     { path: 'widgets', element: <DemoWidgetsPage /> },
    //     { path: 'notices', element: <DemoNoticesPage /> },
    //   ]
    // },

    // Dashboard
    {
      path: '/',
      element: (
        <AdminGuard>
          <AuthGuard>
            <DashboardLayout showNotifications />
          </AuthGuard>
        </AdminGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <PageErrorGuard>
              {' '}
              <GeneralAppPage />{' '}
            </PageErrorGuard>
          ),
        },
        {
          path: ':reservationName',
          element: (
            <PageErrorGuard>
              {' '}
              <GeneralAppPage />{' '}
            </PageErrorGuard>
          ),
        },
        {
          path: 'stripe_payment_test',
          element: CheckEnvironment(SupportedEnvironments.Dev) ? (
            <PageErrorGuard>
              {' '}
              <StripePaymentPage />{' '}
            </PageErrorGuard>
          ) : (
            <Navigate to="/404" replace />
          ),
        },
        {
          path: 'reservations',
          element: (
            <PageErrorGuard>
              {' '}
              <ReservationsPage />{' '}
            </PageErrorGuard>
          ),
        },
        {
          path: 'test',
          element: CheckEnvironment(SupportedEnvironments.Dev) ? (
            <PageErrorGuard>
              {' '}
              <TestPage />{' '}
            </PageErrorGuard>
          ) : (
            <Navigate to="/404" replace />
          ),
        }, // dev only route
        {
          path: 'reservation',
          children: [
            {
              path: ':reservationName',
              element: (
                <PageErrorGuard>
                  <SignatureProvider>
                    <RegistrationGuard>
                      <Outlet />
                    </RegistrationGuard>
                  </SignatureProvider>
                </PageErrorGuard>
              ),
              children: [
                { index: true, element: <ReservationPage /> },
                { path: ':showReview', element: <ReservationPage /> },
              ],
            },
            {
              path: 'travel-insurance/:reservationName',
              children: [
                {
                  index: true,
                  element: (
                    <PageErrorGuard>
                      <SignatureProvider>
                        <RegistrationGuard>
                          <TravelInsurancePage />
                        </RegistrationGuard>
                      </SignatureProvider>
                    </PageErrorGuard>
                  ),
                },
              ],
            },
            {
              path: 'invoice/:reservationName',
              children: [
                {
                  index: true,
                  element: (
                    <PageErrorGuard>
                      <SignatureProvider>
                        <RegistrationGuard>
                          <InvoicePage />
                        </RegistrationGuard>
                      </SignatureProvider>
                    </PageErrorGuard>
                  ),
                },
              ],
            },
            { index: true, element: <Navigate to={WritePostLoginPath(Roles.User)} replace /> },
          ],
        },
        {
          path: 'properties',
          element: (
            <PageErrorGuard>
              {' '}
              <ListingsPage />{' '}
            </PageErrorGuard>
          ),
        },
        {
          path: 'property',
          children: [
            {
              path: ':listingId',
              element: (
                <PageErrorGuard>
                  {' '}
                  <ListingPage />{' '}
                </PageErrorGuard>
              ),
            },
            { index: true, element: <Navigate to={WritePostLoginPath(Roles.User)} replace /> },
          ],
        },
        {
          path: 'payment',
          children: [
            {
              path: 'confirm',
              element: (
                <PageErrorGuard>
                  {' '}
                  <PaymentConfirmationPage />{' '}
                </PageErrorGuard>
              ),
            },
            { index: true, element: <Navigate to={WritePostLoginPath(Roles.User)} replace /> },
          ],
        },
        // These depend on the listings/resports api:
        // { path: 'villa', element: <ComingSoonPage /> },
        // { path: 'basics', element: <ComingSoonPage /> },
        // { path: 'resort', element: <ComingSoonPage /> },
        // { path: 'safety', element: <ComingSoonPage /> },
        // { path: 'contacts', element: <ComingSoonPage /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            {
              path: 'account',
              element: (
                <PageErrorGuard>
                  {' '}
                  <UserAccountPage />{' '}
                </PageErrorGuard>
              ),
            },
          ],
        },
        { path: 'permission-denied', element: <PermissionDeniedPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: 'error', element: <ErrorPage /> },
        { path: 'logout', element: <LogOutPage /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
